import React from "react";
import { Modal } from "react-bootstrap";

const VerifyHashPopup = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered className="crash-history-popup verify-popup">
      <Modal.Header closeButton>
        <Modal.Title>Crash History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="verify-content">
            <div className="top-content">
                <h6>CrashGame: 4,795,679</h6>
                <p>on 5/18/2023 at 6:32 PM</p>
                <h5>4.12X</h5>
            </div>
           <div className="bottom-section">
            <div className="hash">
                <p>Hash</p>
                <div className="copy-content">
               <h6> c642fd7a8362dc371f7a9c88ff415ced1600d7563cd05b4f50bebc5829f12e91</h6>
                </div>
                <i class="las la-copy"></i>
            </div>
            <div className="hash">
                <p>Seed</p>
                <div className="copy-content">
                <h6>0000000000000000001b34dc6a1e86083f95500b096231436e9b25cbdd0075c4</h6>
                </div>
                <i class="las la-copy"></i>
            </div>
           </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default VerifyHashPopup;
