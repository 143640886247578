/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Chart from "react-apexcharts";
import { crashInstance } from "../config/axiosConfig";
// import moment from "moment";
const capitalizeFirstLowercaseRest = (str) => {
  return (
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  );
};
let allProfits = [];

const LiveStats = () => {
  const [statsData, setStatsData] = useState([]);
  const getStatsData = async () => {
    await crashInstance
      .get("/getLiveStats")
      .then((response) => {
        setStatsData([response?.data?.bettingData]);
      });
  };

  useEffect(() => {
    getStatsData();
  }, [])

  useEffect(() => {
    if (statsData) {
      statsData[0]?.forEach((el) => allProfits.push(el?.profit || 0))
    }
    console.log(allProfits)
  }, [statsData])


  const series = [
    {
      name: "Profit",
      data: allProfits,
    },
  ];
  const options = {
    chart: {
      fontFamily: "Montserrat",
      zoom: {
        enabled: false,
      },
      type: "area",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      area: {
        fillTo: "origin",
      },
    },
    colors: ["#fff"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      show: false,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },

    xaxis: {
      labels: {
        show: false,
        formatter: function (value) {
          return value;
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
        formatter: (value) => {
          return value;
        },
      },
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      fillSeriesColor: false,
      theme: ["#000"],
      style: {
        fontSize: "12px",
        fontFamily: undefined,
        colors: ["#fff"],
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },

      marker: {
        show: true,
      },
      items: {
        display: "block",
      },
      fixed: {
        enabled: false,
        position: "topRight",
        offsetX: 0,
        offsetY: 50,
      },
    },
  };

  function addValuesWithKey(arr, key) {
    const totalSum = arr && arr?.reduce((acc, curr) => acc + (curr[key] || 0), 0);
    return totalSum;
  }
  const totalSum = addValuesWithKey(statsData[0], 'amount');
  const totalProfit = addValuesWithKey(statsData[0], 'profit');

  let winCount = 0;
  let lossCount = 0;
  allProfits && allProfits.length > 0 && allProfits?.forEach(item => {
    if (item > 0) {
      winCount++;
    } else {
      lossCount++;
    }
  });
  return (
    <div className="live-stats-content">
      
      <div className="live-stats-heading">
        <div className="stats-top-status">
          <p>Wagered</p>
          <span>{totalSum}</span>
        </div>
        <div className="stats-top-status">
          <p>Win</p>
          <span>
            <p>{winCount}</p>
          </span>
        </div>
        <div className="stats-top-status">
          <p>Loss</p>
          <span>
            <p>{lossCount}</p>
          </span>
        </div>
        <div className="stats-top-status">
          <p>Profit</p>
          <span>
            <p>{totalProfit}</p>
          </span>
        </div>
      </div>
      <Chart
        className="statsChart"
        options={options}
        series={series}
        type="area"
        width="100%"
        height="300px"
      />

      <div className="live-stats-table">
        <div className="dice-history">
          <Table striped bordered variant="dark" responsive>
            <thead>
              <tr>
                <th>Game</th>
                <th>Time</th>
                <th>Wagered </th>
                {/* <th>Multiplier</th> */}
                <th>profit</th>
              </tr>
            </thead>
            <tbody className="tbody_slot">
              {statsData && statsData[0]?.map((el) => (
                <tr>
                  <td>{capitalizeFirstLowercaseRest(el.gameType)}</td>
                  <td>{el.createdAt}</td>
                  <td>{el.amount}</td>
                  <td className={`${(el?.profit?.toString().includes('-') || el?.profit === 0 || el?.profit === '0') ? 'loss-amt' : 'win-amt'}`}>
                    {el?.profit ? el.profit.toFixed(2).replace('-', '') : 0.00}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="showing_num_records">Showing latest 20 records</div>
    </div>
  );
};


export default LiveStats;
