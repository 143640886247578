import React from 'react';
import Game from './components/game';
import { ToastContainer, toast } from "react-toastify";
import {userInstance} from './config/axiosConfig'
import { useState } from 'react';
import { getCookie } from './utils/cookies';
import { useEffect } from 'react';
import UserContext from './context/UserContext';

const App = () => {
  const [user, setUser] = useState({});
  const getProfile = async () => {
    try {
      const res = await userInstance.get('');
      const { data } = res;
      if (data) {
        setUser(data);
      }
    } catch (error) {
      toast.error(error?.response?.data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };
  useEffect(() => {
    if (Object.keys(user)?.length === 0 && getCookie("token")) {
      getProfile();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  return (
    <div className='app'>
      <UserContext.Provider
          value={{
            user,
            setUser,
          }}
        > 
      <Game />
      </UserContext.Provider>
      <ToastContainer />
    </div>
  )
}
export default App