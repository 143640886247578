import React, { useEffect, useState, useRef, useContext } from "react";
import { Form, Tabs, Tab, Button, Modal } from "react-bootstrap";
import "../css/App.css";
import "react-toastify/dist/ReactToastify.css";
import SomeChart from "./SomeChart";
import { socket } from "../config/socket";
import "bootstrap/dist/css/bootstrap.min.css";
import { getCookie } from '../utils/cookies'
import UserContext from '../context/UserContext'
import { crashInstance, gameInstance } from "../config/axiosConfig";
import "./game.css";
import CrashHistory from "./modal/crashHistory";
import VerifyHashPopup from "./modal/verifyHashPopup";
import doller from "../images/doller.svg"
import percentage from "../images/percentage.png"
import fairnessicon from "../images/fairnessicon.svg"
import livestats from "../images/live.png"
import FainessPopup from "./fainesspopup";
import LiveStats from "./livestats";
import click from "../assets/sound/click.mp3";
import counting from "../assets/sound/counting.MP3";
import lose from "../assets/sound/lose.MP3";
import win from "../assets/sound/win.wav";

function Game() {
  const ref = useRef();
  const { user } = useContext(UserContext);
  const [betAmount, setBetAmount] = useState(50);
  const [autoPayoutMultiplier, setAutoPayoutMultiplier] = useState(2);
  const [numberOfBetValue, setNumberOfBetValue] = useState(0)
  // eslint-disable-next-line
  const [userData, setUserData] = useState(null);
  // eslint-disable-next-line
  const [multiplier, setMultiplier] = useState(null);
  const [liveMultiplier, setLiveMultiplier] = useState("Connecting");
  const [liveMultiplierSwitch, setLiveMultiplierSwitch] = useState(false);
  // eslint-disable-next-line
  const [announcement, setAnnouncement] = useState("");
  const [betActive, setBetActive] = useState(false);
  // eslint-disable-next-line
  const [crashHistory, setCrashHistory] = useState([]);
  // eslint-disable-next-line
  const [roundIdList, setRoundIdList] = useState([]);
  const [bBettingPhase, setbBettingPhase] = useState(false);
  const [bettingPhaseTime, setBettingPhaseTime] = useState(-1);
  const [bBetForNextRound, setbBetForNextRound] = useState(false);
  const [hookToNextRoundBet, setHookToNextRoundBet] = useState(false);
  // eslint-disable-next-line
  const [liveBettingTable, setLiveBettingTable] = useState();
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState("");
  const [globalTimeNow, setGlobalTimeNow] = useState(0);
  const [chartData, setChartData] = useState({ datasets: [] });
  const [chartOptions, setChartOptions] = useState({});
  const [chartSwitch, setChartSwitch] = useState(false);
  const [gamePhaseTimeElapsed, setGamePhaseTimeElapsed] = useState();
  // eslint-disable-next-line
  const [startTime, setStartTime] = useState();
  const [streakList, setStreakList] = useState([]);
  // eslint-disable-next-line
  const [tenNumbers, setTenNumbers] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
  const [crashpopup, setCrashPopup] = useState(false);
  const [verifyPopup, setVerifyPopup] = useState(false)
  // eslint-disable-next-line
  const [numberOfBet, setNumberOfBet] = useState(0);
  const [onWinValue, setOnWinValue] = useState(0)
  const [onLossValue, setOnLossValue] = useState(0)
  const [stopLossValue, setStopLossValue] = useState(0)
  const [stopProfitValue, setStopProfitValue] = useState(0)
  const multiplierCount = useRef([]);
  const timeCount_xaxis = useRef([]);
  const realCounter_yaxis = useRef(5);
  const [autoBetActive, setAutoBetActive] = useState(false)
  const [winOrCrash, setWinOrCrash] = useState(0);
  const [key, setKey] = useState("Manual")
  const [isAutoBetClicked, setIsAutoBetClicked] = useState(false);
  const [clientSeed, setClientSeed] = useState(0);
  const [serverSeed, setServerSeed] = useState(0);
  const [newServerSeed, setNewServerSeed] = useState('');
  const [nonce, setNonce] = useState(0);
  const [resultFairness, setResultFairness] = useState('');
  const [stats, setStats] = useState(false);
  const [fairness, setFairness] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [liveStat, setLiveStats] = useState([]);
  // const [testMode, setTestMode] = useState(false);
  const [errorMess, setEarrorMess] = useState("");
  const [volume, setVolume] = useState(true);
  const [isSoundActive, setIsSoundActive] = useState();
  const [numberBetIsActive, setNumberBetIsActive] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [multiplierAmount, setMultiplierAmount] = useState(0);
  const handleClose = () => {
    setFairness(!fairness);
  };

  useEffect(() => {
    let mul = betAmount * autoPayoutMultiplier
    setMultiplierAmount(mul)
  }, [autoPayoutMultiplier, betAmount])
  useEffect(() => {
    if (localStorage.getItem('transactionStoreVar') === "true") {
      setServerSeed(newServerSeed);
      if (key === "Manual") {
        localStorage.setItem('transactionStoreVar', "false");
      }
      if (key === "Auto" && numberOfBetValue === 0 && isAutoBetClicked) {
        setIsDisable(false)
        localStorage.setItem('transactionStoreVar', "false");
      }
      if (key === "Auto" && numberOfBetValue >= 0 && betActive) {
        localStorage.setItem('transactionStoreVar', "true");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newServerSeed]);

  useEffect(() => {
    // setShowHistory("");
    setVolume(true);
    setIsSoundActive(false);
    localStorage.setItem('sound', false)
  }, []);
  const handlestatsClose = () => {
    setStats(!stats);
  };
  const handleSelectKey = (k) => {
    setBetAmount(50)
    setKey(k)
    setAutoPayoutMultiplier(2);
    setEarrorMess('');
    setErrorMessage("");
  }
  const handleCrashHistory = () => {
    setCrashPopup(!crashpopup)
  }
  const handleVerify = () => {
    setVerifyPopup(!verifyPopup);
    setCrashPopup(false);
  }

  const playAudio = (type) => {
    let sound = localStorage.getItem('sound');
    if (sound === 'false') {
      if (type) {
        const audioEl = document.getElementsByClassName(`audio-${type}`)[0];
        if (audioEl) {
          audioEl.play();
        }
      }
    }
  };

  const updateTransaction = async (updateData) => {
    const transactionStoreVar = localStorage.getItem('transactionStoreVar') === "true";
    const isWin = updateData?.winOrCrash === 'Win';
    const isCrash = updateData?.winOrCrash === 'Crash';

    if (transactionStoreVar) {
      if (stopProfitValue > 0 && isWin) {
        const currentProfit = parseFloat(localStorage.getItem('currentProfit')) || 0;
        const betAmount = updateData?.betAmount;
        const autoPayoutMultiplier = updateData?.autoPayoutMultiplier;

        const newProfit = currentProfit + parseFloat(betAmount * autoPayoutMultiplier);
        localStorage.setItem('currentProfit', newProfit.toString());

        if (parseFloat(localStorage.getItem('currentProfit')) >= stopProfitValue) {
          localStorage.setItem('currentProfit', '0');
          setNumberOfBetValue(0);
        }
      }

      if (stopLossValue > 0 && isCrash) {
        const currentLoss = parseFloat(localStorage.getItem('currentLoss')) || 0;
        const betAmount = updateData?.betAmount;

        const newLoss = currentLoss + parseFloat(betAmount);
        localStorage.setItem('currentLoss', newLoss.toString());

        if (parseFloat(localStorage.getItem('currentLoss')) >= stopLossValue) {
          localStorage.setItem('currentLoss', '0');
          setNumberOfBetValue(0);
        }
      }

      await crashInstance.post(`/updateTransaction`, {
        userId: getCookie("userid"),
        clientSeed: clientSeed,
        serverSeed: localStorage.getItem("localServerSeed") || '',
        nonce: nonce,
        result: liveMultiplier,
        updateData: updateData,
      });
    }
  };


  const checkWinorCrash = (data) => {
    if (autoPayoutMultiplier > data) {
      setWinOrCrash('Crash');
    }
    else {
      setWinOrCrash('Win');
    }
  }

  useEffect(() => {
    const availableChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < 20; i++) {
      randomString +=
        availableChars[Math.floor(Math.random() * availableChars.length)];
    }
    if (!localStorage.getItem("clientseed")) {
      localStorage.setItem("clientseed", randomString);
    }
    setClientSeed(localStorage.getItem("clientseed") ? localStorage.getItem("clientseed") : randomString);
  }, []);

  const handleFairness = async () => {
    await crashInstance.post(`/getFairness`, {
      userId: getCookie("userid"),
      clientSeed: clientSeed,
      nonce: nonce,
      serverSeed: serverSeed,
    }).then((response) => {
      setResultFairness(response?.data?.result);
    });
  };
  const fairnessInputs = { clientSeed, serverSeed, nonce };
  // Define useEffects
  useEffect(() => {
    if (hookToNextRoundBet) {
      if (bBetForNextRound) {
        send_bet(true);
        // setIsDisable(!isDisable);
      } else {
      }
      setHookToNextRoundBet(false);
      setbBetForNextRound(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hookToNextRoundBet]);

  const hitAutobets = () => {
    if (isAutoBetClicked) {
      if (numberOfBetValue > 0 || (numberOfBetValue === 0 && localStorage.getItem('isInfiniteBet'))) {
        if (bBettingPhase) {
          send_bet();
        } else {
          bet_next_round();
        }
        setWinOrCrash(0);
      }
    }
  }
  useEffect(() => {
    if (betActive && autoPayoutMultiplier <= liveMultiplier) {
      user.wallet += betAmount * autoPayoutMultiplier;
      if (parseFloat(onWinValue) > 0) {
        setBetAmount(parseFloat(betAmount) + parseFloat(onWinValue))
      }
      auto_cashout_early();
      setBetActive(false);
      hitAutobets();
    }
    else if (winOrCrash === 'Crash') {
      if (parseFloat(onLossValue) > 0) {
        setBetAmount(parseFloat(betAmount) + parseFloat(onLossValue))
      }
      hitAutobets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveMultiplier, winOrCrash]);

  useEffect(() => {
    if (winOrCrash === "Crash" || winOrCrash === "Win") {
      updateTransaction({ winOrCrash, betAmount, liveMultiplier, autoPayoutMultiplier });

      if (numberBetIsActive && isAutoBetClicked) {
        if (localStorage.getItem('isInfiniteBet') === null) {
          setNumberOfBetValue(prevValue => prevValue - 1);
        }
        setIsAutoBetClicked(false);
      } else {
        setNumberBetIsActive(false);
        setIsAutoBetClicked(false);
      }

      setWinOrCrash(0);
    } else {
      //setTransactionStore(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winOrCrash, numberBetIsActive, isAutoBetClicked, betAmount, liveMultiplier, autoPayoutMultiplier]);


  useEffect(() => {
    let gameCounter = null;
    if (liveMultiplierSwitch) {
      setLiveMultiplier("1.00");
      gameCounter = setInterval(() => {
        let time_elapsed = (Date.now() - globalTimeNow) / 1000.0;
        setGamePhaseTimeElapsed(time_elapsed);
        setLiveMultiplier((1.0024 * Math.pow(1.0718, time_elapsed)).toFixed(2));

        if (multiplierCount.current.length < 1) {
          multiplierCount.current = multiplierCount.current.concat([1]);
          timeCount_xaxis.current = timeCount_xaxis.current.concat([0]);
        }
        if (realCounter_yaxis.current % 5 === 0) {
          multiplierCount.current = multiplierCount.current.concat([
            (1.0024 * Math.pow(1.0718, time_elapsed)).toFixed(2),
          ]);
          timeCount_xaxis.current = timeCount_xaxis.current.concat([
            time_elapsed,
          ]);
        }
        realCounter_yaxis.current += 1;
      }, 1);
    }
    return () => {
      clearInterval(gameCounter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveMultiplierSwitch]);

  useEffect(() => {
    let bettingInterval = null;
    if (bBettingPhase) {
      bettingInterval = setInterval(() => {
        let time_elapsed = (Date.now() - globalTimeNow) / 1000.0;
        let time_remaining = (5 - time_elapsed).toFixed(2);
        setBettingPhaseTime(parseInt(time_remaining));
        if (time_remaining < 0) {
          setbBettingPhase(false);
        }
      }, 1000);
    }
    return () => {
      clearInterval(bettingInterval);
      setBettingPhaseTime("Starting");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bBettingPhase]);

  useEffect(() => {
    if (bBetForNextRound) {
    } else {
    }
  }, [bBetForNextRound]);

  useEffect(() => {
    localStorage.setItem("local_storage_multiplier", autoPayoutMultiplier);
  }, [betAmount, autoPayoutMultiplier]);

  useEffect(() => {
    get_game_status();
    setChartSwitch(true);
    setStartTime(Date.now());
    let getActiveBettorsTimer = setTimeout(
      () => retrieve_active_bettors_list(),
      1000
    );
    return () => {
      clearTimeout(getActiveBettorsTimer);
    };
  }, []);


  const retrieve = async () => {
    if (!getCookie("token")) {
      return;
    }
    try {
      const res = await crashInstance.get(`/retrieve`);
      setMultiplier(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  
  const send_bet = async (chkNext) => {
    if (!getCookie("token")) {
      window.parent.postMessage("loginRequest", '*');
      return;
    }
    if (parseFloat(betAmount) < 1.01) {
      setErrorMessage("Bet amount must be greater than or equal 0.1");
      return;
    }
    localStorage.setItem('transactionStoreVar', 'true');
    setIsAutoBetClicked(key === "Auto");
    if (key === "Auto" && parseInt(numberOfBetValue) === 0) {
      localStorage.setItem('isInfiniteBet', 'true');
    }
    setAutoBetActive(true);
    playAudio("click");
    setNumberBetIsActive(true);
    try {
      await crashInstance.post(`/send_bet`, {
        userId: getCookie("userid"),
        clientSeed: clientSeed,
        nonce: parseInt(nonce) + 1,
        bet_amount: betAmount,
        payout_multiplier: autoPayoutMultiplier,
      });
      setNonce(prevNonce => prevNonce + 1);
      setBetActive(true);
    } catch (error) {
      console.log(error);
    }
    if (chkNext) {
      setIsDisable(false);
    }
  };

  // Socket.io setup
  useEffect(() => {
    retrieve();
    socket.on("news_by_server", function (data) {
      setAnnouncement(data);
    });

    socket.on("start_multiplier_count", function (data) {
      setGlobalTimeNow(Date.now());
      setLiveMultiplierSwitch(true);
    });

    socket.on("stop_multiplier_count", function (data) {
      setLiveMultiplier(data.value);
      checkWinorCrash(data.value);
      localStorage.setItem("localServerSeed", data.serverSeed);
      setNewServerSeed(data.serverSeed);
      setLiveMultiplierSwitch(false);
      setBetActive(false);
    });

    socket.on("crash_history", function (data) {
      setCrashHistory(data);
      let temp_streak_list = [];
      const new_data = data;
      let blue_counter = 0;
      let red_counter = 0;
      for (let i = 0; i < data.length; i++) {
        if (new_data[i] >= 2) {
          blue_counter += 1;
          red_counter = 0;
          temp_streak_list.push(blue_counter);
        } else {
          red_counter += 1;
          blue_counter = 0;
          temp_streak_list.push(red_counter);
        }
      }
      setStreakList(temp_streak_list.reverse());
    });
    socket.on("start_betting_phase", function (data) {
      setGlobalTimeNow(Date.now());
      setLiveMultiplier("Starting");
      setbBettingPhase(true);
      setLiveBettingTable(null);
      setHookToNextRoundBet(true);
      retrieve_active_bettors_list();
      if (autoBetActive) {
        send_bet()
      }
      multiplierCount.current = [];
      timeCount_xaxis.current = [];
    });

    socket.on("receive_live_betting_table", (data) => {
      setLiveBettingTable(data);
      data = JSON.parse(data);
      setTenNumbers(Array(10 - data.length).fill(2));
    });

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const get_game_status = async () => {
    if (!getCookie("token")) {
      window.parent.postMessage("loginRequest", '*')
      //toast.error("You need to login in order to play.", { toastId: "dropChips" });
      return;
    }
    try {
      const res = await crashInstance.get(`/get_game_status`);
      if (res.data.phase === "betting_phase") {
        setGlobalTimeNow(res.data.info);
        setbBettingPhase(true);
      } else if (res.data.phase === "game_phase") {
        setGlobalTimeNow(res.data.info);
        setLiveMultiplierSwitch(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const manual_cashout_early = async () => {
    if (!getCookie("token")) {
      window.parent.postMessage("loginRequest", '*');
      return;
    }
    playAudio("click");
    try {
      const res = await crashInstance.get(`/manual_cashout_early`);
      setUserData(res.data);
      setBetActive(false);
    } catch (error) {
      console.log(error);
    }
  };

  const auto_cashout_early = async () => {
    if (!getCookie("token")) {
      window.parent.postMessage("loginRequest", '*');
      return;
    }
    playAudio("click");
    try {
      const res = await crashInstance.get(`/auto_cashout_early`);
      setUserData(res.data);
      setBetActive(false);
    } catch (error) {
    }
  };
  const bet_next_round = (isCancelCheck) => {
    if (parseFloat(betAmount) < 1.01) {
      setErrorMessage("Bet amount must be greater than or equal 0.1");
      return
    }
    if (isCancelCheck) {
      setIsDisable(false);
      setbBetForNextRound(false);
      setIsAutoBetClicked(false);
      localStorage.removeItem('isInfiniteBet');
      return
    }

    if (!getCookie("token")) {
      window.parent.postMessage("loginRequest", '*')
      return;
    }

    if (!user) {
      return;
    }
    if (user && user.wallet < betAmount) {
      return;
    }
    playAudio("click");
    setIsDisable(true);
    setbBetForNextRound(!bBetForNextRound); //
  };

  const retrieve_active_bettors_list = async () => {
    if (!getCookie("token")) {
      return;
    }
    try {
      await crashInstance.get(`/retrieve_active_bettors_list`);
    } catch (error) {
    }
  };

  const handleKeyDownBetting = (e) => {
    if (e.key === "Enter") {
      if (bBettingPhase) {
        send_bet();
      } else {
        bet_next_round();
      }
    }
  };

  const verifyBetAmount = (e) => {
    let newStr = e.target.value;
    if (newStr.indexOf("-") !== -1) {
      newStr = newStr.replace("-", "");
    }
    setBetAmount(parseFloat(newStr))
    if (parseFloat(newStr) > user.wallet) {
      setErrorMessage("Bet greater than balance");
    } else if (parseFloat(newStr) < 1.01 || newStr === '') {
      setErrorMessage("Bet amount must be greater than or equal 0.1");
    } else {
      setErrorMessage("");
    }
  };

  const verifyMultiplierAmount = (e) => {
    let newStr = e?.target?.value;
    if (newStr.indexOf("-") !== -1) {
      newStr = newStr.replace("-", "");
    }
    if (parseFloat(newStr) === 0 || newStr === '' || parseFloat(newStr) < 1.01) {
      setEarrorMess('Cashout must be greater or equal than 1.01');
    } else {
      setEarrorMess('');
    }
    setAutoPayoutMultiplier(parseFloat(newStr));
    let mul = parseFloat(newStr) * betAmount
    setMultiplierAmount(mul)
  };

  
  useEffect(() => {
    const temp_interval = setInterval(() => {
      setChartSwitch(false);
      sendToChart();
    }, 1);

    return () => {
      clearInterval(temp_interval);
      setChartSwitch(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartSwitch]);


  const sendToChart = () => {
    setChartData({
      point: {
        radius: 1,
        pointStyle: ref.current
      },
      labels: timeCount_xaxis.current,
      datasets: [
        {
          data: multiplierCount.current,
          backgroundColor: "transparent",
          borderColor: "rgba(255, 198, 56, 1)",
          color: "rgba(255, 198, 56, 1)",
          fill: true,
          pointRadius: 0,
          borderWidth: 4,
          pointRotation: 50,
          pointStyle: (ctx) => {
            const index = ctx.dataIndex;
            const dataLength = ctx.dataset.data.length;
            return index === dataLength - 1 ? ref.current : '';
          },
        },
      ],
    });
    setChartOptions({
      events: [],
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0,
        },
      },
      scales: {
        yAxes: {
          position: 'left',
          type: "linear",
          title: {
            display: false,
            text: "value",
          },
          min: 1,
          max: liveMultiplier > 2 ? liveMultiplier : 2,
          ticks: {
            color: "#4d5361",
            maxTicksLimit: 0,
            autoSkip: false,
            callback: function (value, index, values) {
              if (value % 0.5 === 0) return parseFloat(value).toFixed(2) + "x";
            },
            font: {
              family: '"Poppins", sans-serif',
              size: 13,
              fontWeight: 600,
            }
          },
          font: {
            family: '"Poppins", sans-serif'
          },
          grid: {
            display: false,
            color: "#2a2e38",
            drawOnChartArea: false,
            drawBorder: true,
            borderColor: "#2a2e38",
            borderWidth: 2,
            font: {
              family: '"Poppins", sans-serif'
            }
          },
        },
        xAxes: {
          categorySpacing: 0.05,
          type: "linear",
          title: {
            display: false,
            text: "value",
          },
          font: {
            family: '"Poppins", sans-serif'
          },
          max: gamePhaseTimeElapsed > 2 ? gamePhaseTimeElapsed : 2,
          ticks: {
            display: true,
            color: "#4d5361",
            maxTicksLimit: 0,
            autoSkip: false,
            callback: function (value, index, values) {
              if (gamePhaseTimeElapsed < 5) {
                if (value % 1 === 0) return value + "s";
              } else {
                if (value % 5 === 0) return value + "s";
              } 
            },
            font: {
              family: '"Poppins", sans-serif',
              size: 13,
              fontWeight: 500,
            }
          },
          grid: {
            display: false,
            color: "#2a2e38",
            drawOnChartArea: false,
            drawBorder: true,
            borderColor: "#2a2e38",
            borderWidth: 2,
            font: {
              family: '"Poppins", sans-serif'
            }
          },
        },
      },
      plugins: {
        legend: { display: false },
      },
      animation: {
        x: {
          type: "number",
          easing: "linear",
          duration: 0,
          from: 5,
          delay: 0,
        },
        y: {
          type: "number",
          easing: "linear",
          duration: 0,
          from: 5,
          delay: 0,
        },
        loop: true,
      },
    });
  };
  const handleBet = (amt, type) => {
    if (type === 'half') {
      if (amt < 50) {
        setBetAmount(50);
      } else {
        setBetAmount(amt);
      }
    }
    if (type === 'double') {
      setBetAmount(amt > 500 ? 500 : amt);
    }
    if (type === 'max') {
      setBetAmount(500);
    }
  };

  const handleBetAmount = (e) => {
    let newStr = e.target.value;
    if (newStr.indexOf("-") !== -1) {
      newStr = newStr.replace("-", "");
    }
    setBetAmount(parseFloat(newStr))
    let mul = parseFloat(newStr) * autoPayoutMultiplier
    setMultiplierAmount(mul)
    if (parseFloat(newStr) > user.wallet) {
      setErrorMessage("Bet greater than balance");
    } else if (parseFloat(newStr) < 1.01 || newStr === '') {
      setErrorMessage("Bet amount must be greater than or equal 0.1");
    } else {
      setErrorMessage("");
    }
  }

  const handleAutoBetField = (e, inputFieldName) => {
    let newStr = e?.target?.value;
    if (newStr.indexOf("-") !== -1) {
      newStr = newStr.replace("-", "");
    }
    if (inputFieldName === 'numberOfBet') {
      setNumberOfBetValue(parseInt(newStr));
      setNumberOfBet(parseInt(newStr));
    }
    if (inputFieldName === 'onWin') {
      setOnWinValue(parseInt(newStr))
    }
    if (inputFieldName === 'onLoss') {
      setOnLossValue(parseInt(newStr))
    }
    if (inputFieldName === 'stopOnLoss') {
      setStopLossValue(parseInt(newStr))
    }
    if (inputFieldName === 'stopOnProfit') {
      setStopProfitValue(parseInt(newStr))
    }
  }
  const fetchData = async () => {
    try {
      const response = await gameInstance.get('/get-multiplier-history');
      if (response.status === 200) {
      }
    } catch (error) {
      console.log('error', error);
    }
  }
  useEffect(() => {
    fetchData();
  }, [])
  const handleServerSeedFairness = (e) => {
    setServerSeed(e?.target?.value);
  }
  const handleNonceFairness = (e) => {
    setNonce(e?.target?.value);
  }

  const handleSound = () => {
    let sound = localStorage.getItem('sound');
    if (sound === 'false' || sound === false) {
      localStorage.setItem('sound', true);
      setIsSoundActive(true)
    }
    else {
      localStorage.setItem('sound', false);
      setIsSoundActive(false)
    }
  }

  return (
    <div className="App">
      <div className="crash-game-page">
        <div className="main-content">
          <div className="left-content">
            <div className="limbo-tab limbo-content">
              <Tabs
                id="uncontrolled-tab-example"
                className="mb-3"
                activeKey={key}
                onSelect={(k) => handleSelectKey(k)}
              >
                <Tab eventKey="Manual" title="Manual" className="form">
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Bet Amount</Form.Label>
                    <div className="bet-input pdd-200 input-with-bet">
                      <img src={doller} alt='' />
                      <Form.Control
                        step="0.1"
                        type="number"
                        placeholder="Bet Amount"
                        value={betAmount}
                        // disabled={bBetForNextRound}
                        onChange={handleBetAmount}
                        min={0.0}
                        onKeyDown={handleKeyDownBetting}
                      />
                      <div className="bet-input-btn bet-value">
                        <Button onClick={() => handleBet(betAmount / 2, 'half')}>1/2</Button>
                        <Button onClick={() => handleBet(betAmount * 2, 'double')}>2x</Button>
                        <Button onClick={() => handleBet(500, 'max')}>Max</Button>
                      </div>
                    </div>
                    {errorMessage && <p className="text-danger pt-3" > {errorMessage}</p>}
                  </Form.Group>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Cashout At</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="0.0"
                      onChange={(e) => verifyMultiplierAmount(e)}
                      onKeyDown={handleKeyDownBetting}
                      value={autoPayoutMultiplier}
                      disabled={betActive ? true : null}
                    // disabled={bBetForNextRound} 
                    />
                    {errorMess && <p className="text-danger pt-3" > {errorMess}</p>}
                  </Form.Group>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Multiplier</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="0.0"
                      value={multiplierAmount}
                      disabled
                    />
                  </Form.Group>
                  {bBettingPhase && !betActive ? (
                    <div className="crash-tab">
                      <Button
                        class="css-button css-button-3d css-button-3d--grey"
                        onClick={send_bet}
                        disabled={errorMess ? true : isDisable ? isDisable : false}
                      >
                        Send Bet
                      </Button>
                    </div>
                  ) : (
                    <>
                      {betActive ? (
                        <div className="crash-tab">
                          <Button
                            class="css-button css-button-3d css-button-3d--grey"
                            onClick={manual_cashout_early}
                          >
                            {" "}
                            {betActive && liveMultiplier > 1 ? (
                              <span>
                                Cashout at{" - "}
                                {(liveMultiplier * betAmount).toFixed(2)}
                              </span>
                            ) : (
                              "Starting"
                            )}
                          </Button>
                        </div>
                      ) : (
                        <div className="bet-btn">
                          <Button
                            class={`css-button css-button-3d css-button-3d--grey `}
                            onClick={() => bet_next_round(bBetForNextRound)}
                            disabled={errorMess ? true : false}
                          >
                            {bBetForNextRound ? "Cancel Bet" : "Bet Next round"}{" "}
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </Tab>
                <Tab eventKey="Auto" title="Auto">
                  <div className="scroll-input">
                    <Form.Group className="form-group" controlId="formBasicEmail">
                      <Form.Label>Bet Amount</Form.Label>
                      <div className="bet-input pdd-200 input-with-bet">
                        <img src={doller} alt='' />
                        <Form.Control
                          step="0.1"
                          type="number"
                          placeholder="Type Your Bet Amount"
                          onChange={(e) => verifyBetAmount(e)}
                          value={betAmount}
                          disabled={betActive ? true : false}
                          onKeyDown={handleKeyDownBetting}
                        />
                        <div className="bet-input-btn bet-value">
                          <Button onClick={() => handleBet(betAmount / 2, 'half')}>1/2</Button>
                          <Button onClick={() => handleBet(betAmount * 2, 'double')}>2x</Button>
                          <Button onClick={() => handleBet(500, 'max')}>Max</Button>
                        </div>

                      </div>
                      {errorMessage && <p className="text-danger pt-3" > {errorMessage}</p>}
                    </Form.Group>
                    <div className="bet-input-cash">
                      <Form.Group className="form-group" controlId="formBasicEmail">
                        <Form.Label>Cashout At</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="0.0"
                          onChange={(e) => verifyMultiplierAmount(e)}
                          onKeyDown={handleKeyDownBetting}
                          value={autoPayoutMultiplier}
                          disabled={betActive ? true : false}
                        />
                        {errorMess && <p className="text-danger pt-3" > {errorMess}</p>}
                      </Form.Group>
                      <Form.Group className="form-group" controlId="formBasicEmail">
                        <Form.Label>Number of Bet</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Payout Multiplier"
                          onChange={(e) => handleAutoBetField(e, 'numberOfBet')}
                          onKeyDown={handleKeyDownBetting}
                          value={numberOfBetValue}
                          min={0}
                          disabled={betActive ? true : false}
                        />
                      </Form.Group>
                    </div>
                    <Form.Group className="form-group" controlId="formBasicEmail">
                      <Form.Label>On Win </Form.Label>
                      <div className='bet-input pdd-200'>
                        <img src={percentage} alt='percentage' />
                        <Form.Control
                          type="number"
                          placeholder=""
                          onChange={(e) => handleAutoBetField(e, 'onWin')}
                          onKeyDown={handleKeyDownBetting}
                          value={onWinValue}
                          disabled={betActive ? true : false}
                        />
                        <div className='bet-input-btn'>
                          <Button>Increase by:</Button>
                          <Button>Reset</Button>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="form-group" controlId="formBasicEmail">
                      <Form.Label>On Lose </Form.Label>
                      <div className='bet-input pdd-200'>
                        <img src={percentage} alt='percentage' />
                        <Form.Control
                          type="number"
                          placeholder=""
                          onChange={(e) => handleAutoBetField(e, 'onLoss')}
                          onKeyDown={handleKeyDownBetting}
                          value={onLossValue}
                          disabled={betActive ? true : false}
                        />
                        <div className='bet-input-btn'>
                          <Button>Increase by:</Button>
                          <Button>Reset</Button>
                        </div>
                      </div>
                    </Form.Group>
                    <div className='profit-content'>
                      <Form.Group className='stop-profit'>
                        <Form.Label>Stop on Profit </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder='0.00'
                          onChange={(e) => handleAutoBetField(e, 'stopOnProfit')}
                          onKeyDown={handleKeyDownBetting}
                          value={stopProfitValue}
                          disabled={betActive ? true : false}
                        />
                      </Form.Group>
                      <Form.Group className='stop-profit'>
                        <Form.Label>Stop on Loss </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder='0.00'
                          onChange={(e) => handleAutoBetField(e, 'stopOnLoss')}
                          onKeyDown={handleKeyDownBetting}
                          value={stopLossValue}
                          disabled={betActive ? true : false}
                        />
                      </Form.Group>
                    </div>
                    <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Multiplier</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="0.0"
                      value={multiplierAmount}
                      disabled
                    />
                  </Form.Group>
                  </div>
                  {/* <br /> */}
                  {bBettingPhase && !betActive ? (
                    <div className="crash-tab">
                      <Button
                        class="css-button css-button-3d css-button-3d--grey"
                        onClick={send_bet}
                        disabled={errorMess ? true : isDisable ? isDisable : false}
                      >
                        {key === 'Auto' ? 'Send Autobet' : 'Send Bet'}
                      </Button>
                    </div>
                  ) : (
                    <>
                      {betActive ? (
                        <div className="crash-tab">
                          <Button
                            class="css-button css-button-3d css-button-3d--grey"
                            onClick={manual_cashout_early}
                          >
                            {" "}
                            {betActive && liveMultiplier > 1 ? (
                              <span>
                                Cashout at{" -- "}
                                {(liveMultiplier * betAmount).toFixed(2)}
                              </span>
                            ) : (
                              "Starting"
                            )}
                          </Button>
                        </div>
                      ) : (
                        <div className="bet-btn btn-crash">
                          <Button
                            class={`btn-crash css-button css-button-3d css-button-3d--grey `}
                            onClick={() => bet_next_round(bBetForNextRound)}
                            disabled={errorMess ? true : false}
                          >
                            {bBetForNextRound ? "Cancel Bet" : "Bet Next round"}{" "}
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </Tab>
              </Tabs>
            </div>
            <div className="limbo-game-footer">
              <div className="volume-btn">
                {(isSoundActive === 'false' || isSoundActive === false) ?
                  <i className="las la-volume-up" role="presentation" onClick={handleSound} />
                  : <i className="las la-volume-off" role="presentation" onClick={handleSound} />}
              </div>
              <div className="limbo-game-btns">
                <div className="fairness-btn" onClick={handlestatsClose}>
                  <img src={livestats} alt="livestats" />
                  <span>Live stats</span>
                </div>
                <div className="fairness-btn" onClick={handleClose}>
                  <img src={fairnessicon} alt="fairness" />
                  <span>Fairness</span>
                </div>
              </div>
            </div>
          </div>
          <div className="right-content">
            <div className="crash-game">
              <div className="graph-box">
                {chartData ? (
                  <SomeChart
                    chartData={chartData}
                    chartOptions={chartOptions}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="number-value">
                {(() => {
                  if (bBettingPhase) {
                    return <h3>{bettingPhaseTime}</h3>;
                  } else {
                    return (
                      <h2 className={` ${!liveMultiplierSwitch &&
                        liveMultiplier !== "Starting" 
                        // && liveMultiplier !== "Connecting"
                        ? "multipler_crash_value_message"
                        : ""
                        }`}>
                        {liveMultiplier !== "Starting"
                          ? liveMultiplier + "x"
                          : "Starting"}
                      </h2>
                    );
                  }
                })()}
              </div>
            </div>
           
          </div>
        </div>
      </div>
      <div>
        <audio className="audio-click" muted={!volume}>
          <source src={click}></source>
        </audio>
        <audio className="audio-counting" muted={!volume}>
          <source src={counting}></source>
        </audio>
        <audio className="audio-lose" muted={!volume}>
          <source src={lose}></source>
        </audio>
        <audio className="audio-win" muted={!volume}>
          <source src={win}></source>
        </audio>
      </div>
      <Modal
        show={fairness}
        onHide={handleClose}
        centered
        className="fairness-popup"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Fairness</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FainessPopup
            inputs={fairnessInputs}
            resultFairness={resultFairness}
            handleFairness={handleFairness}
            handleServerSeedFairness={handleServerSeedFairness}
            handleNonceFairness={handleNonceFairness}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={stats}
        onHide={handlestatsClose}
        centered
        className="fairness-popup"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Live Stats</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LiveStats />
        </Modal.Body>
      </Modal>
      <CrashHistory show={crashpopup} handleClose={handleCrashHistory} handleVerify={handleVerify} roundIdList={roundIdList} streakList={streakList} />
      <VerifyHashPopup show={verifyPopup} handleClose={handleVerify} />
    </div>
  );
}

export default Game;