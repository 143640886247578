import React, { useState } from "react";
import { Tabs, Tab, Form, Button } from "react-bootstrap";
import copy from "../images/copy-icon.png";
import arrow from "../images/arrow-icon.svg";

const FainessPopup = ({
  inputs,
  resultFairness,
  handleFairness,
  handleServerSeedFairness,
  handleNonceFairness,
}) => {
  const { clientSeed, serverSeed, nonce } = inputs ? inputs : {};
  return (
    <div className="fainess-popup-content">
      <div className="limbo-tab limbo-tab2">
        <Tabs defaultActiveKey="seeds" id="uncontrolled-tab-example">
          <Tab eventKey="seeds" title="Seeds">
            <Seeds
              clientSeed={clientSeed}
              serverSeed={serverSeed}
              nonce={nonce}
            />
          </Tab>
          <Tab eventKey="varify" title="Verify">
            <Varify
              clientSeed={clientSeed}
              serverSeed={serverSeed}
              nonce={nonce}
              handleFairness={handleFairness}
              handleServerSeedFairness={handleServerSeedFairness}
              handleNonceFairness={handleNonceFairness}
              resultFairness={resultFairness}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default FainessPopup;

const Seeds = ({ clientSeed, serverSeed, nonce }) => {
  return (
    <div className="seeds-content">
      <Form>
        <div className="tab-box">
          <Form.Group>
            <Form.Label>Active Client Seed</Form.Label>
            <div className="limbo-input">
              <Form.Control type="text" value={clientSeed} />
              {/* <Form.Control type='text' value={seeds?.seeds?.seeds?.clientSeed}/> */}
              <div className="limbo-input-btn">
                <CopyButton copyText={clientSeed} />
                {/* <Button className="limbo-btn-copy">
                  <img src={copy} alt="copy" />
                </Button> */}
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Active Server Seed (Hashed)</Form.Label>
            <div className="limbo-input">
              <Form.Control type="text" value={serverSeed} />
              {/* <Form.Control type='text' value={seeds?.seeds?.seeds?.serverSeed}/> */}
              <div className="limbo-input-btn">
                <CopyButton copyText={serverSeed} />
                {/* <Button className="limbo-btn-copy">
                  <img src={copy} alt="copy" />
                </Button> */}
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Nonce</Form.Label>
            <div className="limbo-input">
              {/* <Form.Control type='number' value={seeds?.seeds?.seeds?.nonce}/> */}
              <Form.Control type="number" value={nonce} />
              <div className="limbo-input-btn">
                <CopyButton copyText={nonce} />
                {/* <Button className="limbo-btn-copy">
                  <img src={copy} alt="copy" />
                </Button> */}
              </div>
            </div>
          </Form.Group>
        </div>
      </Form>
      {/* <div className='seed-bottom'>
        <h4>Rotate Seed Pair</h4>
        <Form.Group>
          <Form.Label>New Client Seed</Form.Label>
          <div className='limbo-input limbo-nobg-input'>
            <Form.Control type='text' />
            <div className='limbo-change'>
              <Button>Change</Button>
            </div>
          </div>
        </Form.Group>
        <Form.Group>
          <Form.Label>Next Server Seed (Hashed)</Form.Label>
          <div className='limbo-input'>
            <Form.Control type='number' />
            <div className='limbo-input-btn'>
              <Button className='limbo-btn-copy'>
                <img src={copy} alt='copy' />
              </Button>
            </div>
          </div>
        </Form.Group>
      </div> */}
    </div>
  );
};

const Varify = ({
  clientSeed,
  serverSeed,
  nonce,
  handleFairness,
  handleServerSeedFairness,
  handleNonceFairness,
  resultFairness,
}) => {
  return (
    <div className="varify-content">
      {/* <div className='varify-box'> */}
      {/* <span>{seeds.finalResult}x</span> */}
      {/* <span>50x</span> */}
      {/* </div> */}

      <Form>
        <div className="tab-box">
          <Form.Group>
            <div className="tab-box-game">
              <Form.Label>Game</Form.Label>
              <Form.Select>
                <option value="limbo">limbo</option>
                <option value="dice">dice</option>
                <option value="crash">crash</option>
                <option value="plinko">plinko</option>
              </Form.Select>
              {/* <select>
                <option>limbo</option>
                <option>dice</option>
                <option>crash</option>
                <option>plinko</option>
              </select> */}
            </div>
          </Form.Group>

          <Form.Group>
            <Form.Label>Client Seed</Form.Label>
            <div className="limbo-input">
              <Form.Control type="text" value={clientSeed} />
              {/* <Form.Control type='text' value={seeds.seeds.seeds.clientSeed}/> */}
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Server Seed</Form.Label>
            <div className="limbo-input">
              <Form.Control
                type="text"
                value={serverSeed}
                onChange={handleServerSeedFairness}
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Nonce</Form.Label>
            <div className="limbo-input">
              <Form.Control
                type="number"
                value={nonce}
                onChange={handleNonceFairness}
              />
              <div className="limbo-input-btn arrow-bottom">
                <Button className="limbo-btn-copy">
                  <img src={arrow} alt="arrow" />
                </Button>
              </div>
              <div className="limbo-input-btn arrow-top">
                <Button className="limbo-btn-copy">
                  <img src={arrow} alt="arrow" />
                </Button>
              </div>
            </div>
          </Form.Group>
        </div>
      </Form>
      {/* <div className='varify-bottom-text' onClick={seeds.fairnessCalculate}> */}
      <div className="varify-bottom-text" onClick={handleFairness}>
        <span className="calcution-btn">View Calculation Breakdown dd</span>
      </div>
      <div className="final_result">Final result: {resultFairness}</div>
    </div>
  );
};

const CopyButton = ({ copyText }) => {
  const [isCopied, setIsCopied] = useState(false);
  return (
    <div className="copy-btn-section">
      {isCopied ? <span>Copied</span> : <span>Copy</span>}
      <Button
        className="limbo-btn-copy"
        onClick={() => {
          navigator.clipboard.writeText(copyText);
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 300);
        }}
      >
        <img src={copy} alt="copy" />
      </Button>
    </div>
  );
};
