import { createContext } from "react";
import { getCookie } from "../utils/cookies";

const UserContext = createContext({
  token: getCookie("token") ? getCookie("token") : "",
  user: {},
  setUser: () => {},
  friends: [],
  setFriends: () => {},
  posts: [],
  setPosts: () => {},
});

export default UserContext;
