import React from "react";
import { Modal, Table } from "react-bootstrap";

const CrashHistory = ({ show, handleClose, handleVerify, roundIdList, streakList }) => {
  return (
    <Modal show={show} onHide={handleClose} centered className="crash-history-popup">
      <Modal.Header closeButton>
        <Modal.Title>Crash History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Table striped responsive>
        <thead>
          <tr>
            <th>Game Id</th>
            <th>Crash History</th>
            <th>Streak</th>
          </tr>
        </thead>
        <tbody>
        {roundIdList.map((crash, index, array) => {
                return (
                  <tr>
                  <td>{crash.roundNumber}</td>
                  <td>{crash.multiplierCrash}x</td>
                  <td>{streakList[index]}</td>
                </tr>
                );
              })}
        </tbody>
      </Table>
      </Modal.Body>
    </Modal>
  );
};
export default CrashHistory;
