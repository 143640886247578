import axios from "axios";
import { getCookie } from "../utils/cookies";
import { serverPath, landingServer } from "../config/keys";

export const getToken = () => {
  const tokenData = getCookie("token")
    ? getCookie("token")
    : "";
  return tokenData;
};

export const getAuthorizationHeader = () => `Bearer ${getToken()}`;

  export const crashInstance = axios.create({
    baseURL: `${serverPath}`,
    headers: { authorization: getAuthorizationHeader() },
  });


  export const userInstance = axios.create({
    baseURL: `${landingServer}/v1/users`,
    headers: { Authorization: getAuthorizationHeader() },
  });

  export const gameInstance = axios.create({
    baseURL: `${serverPath}/v1/games`,
    headers: { authorization: getAuthorizationHeader() },
  });

